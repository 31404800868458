var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"ArticleBox",class:{'ArticleBox--horizontal': _vm.imagePosition === 'left'},attrs:{"id":`article-${_vm.article._id}`,"aria-label":"article","itemprop":"blogPost","itemscope":"itemscope","itemtype":"http://schema.org/BlogPosting"}},[_c('nuxt-link',{staticClass:"ArticleBox__imageLink",attrs:{"to":_vm.article.page_meta && _vm.article.page_meta.slug
        ? _vm.localePath({ name: 'article-slug', params: { slug: _vm.article.page_meta.slug }})
        : '#'}},[(_vm.$themeSettings.components.ArticleBox.image.enable)?_c('figure',{staticClass:"ArticleBox__imageWrapper",attrs:{"itemprop":"image","itemscope":"itemscope","itemtype":"https://schema.org/ImageObject"}},[(_vm.article.image && _vm.article.image.length)?_c('AppImage',{attrs:{"src":_vm.article.image[0].path,"width":_vm.$themeSettings.components.ArticleBox.image.width,"height":_vm.$themeSettings.components.ArticleBox.image.height,"alt":_vm.article.image[0].alt ? _vm.article.image[0].alt : _vm.article.title,"crop":_vm.$themeSettings.components.ArticleBox.image.crop,"lazy":_vm.lazyImage,"itemprop":"contentUrl","image-class":"ArticleBox__image"}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"ArticleBox__content"},[_c('header',{staticClass:"ArticleBox__header"},[(_vm.article.title)?_c('AppTitle',{staticClass:"Title--epsilon",attrs:{"heading-level":_vm.headingLevel,"itemprop":"name headline"}},[_c('nuxt-link',{attrs:{"to":_vm.article.page_meta && _vm.article.page_meta.slug
              ? _vm.localePath({ name: 'article-slug', params: { slug: _vm.article.page_meta.slug }})
              : '#'}},[_vm._v(_vm._s(_vm._f("prepositions")(_vm._f("truncate")(_vm.article.title,80))))])],1):_vm._e(),(_vm.$themeSettings.components.ArticleBox.articleInfo.enable)?_c('div',{staticClass:"ArticleBox__info"},[(_vm.$themeSettings.components.ArticleBox.articleInfo.date.enable)?_c('div',{staticClass:"ArticleBox__date"},[_c('span',{staticClass:"ArticleBox__infoIconWrapper"},[(_vm.$themeSettings.components.ArticleBox.showIcons && _vm.$themeSettings.components.ArticleBox.articleInfo.date.icon)?_c('svgicon',{attrs:{"icon":require('@icon/calendar.svg?raw')}}):_vm._e()],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.dateFormat(_vm.article.date_published, _vm.$store.state.locale.contentLocale))}})]):_vm._e(),(
            _vm.$themeSettings.components.ArticleBox.articleInfo.category.enable
              && _vm.article.article_category_reference && _vm.article.article_category_reference[0]
          )?_c('div',{staticClass:"ArticleBox__category"},[_c('span',{staticClass:"ArticleBox__infoIconWrapper"},[(_vm.$themeSettings.components.ArticleBox.showIcons && _vm.$themeSettings.components.ArticleBox.articleInfo.category.icon)?_c('svgicon',{attrs:{"icon":require('@icon/folder.svg?raw')}}):_vm._e()],1),_vm._l((_vm.article.article_category_reference),function(category,index){return (index < _vm.$themeSettings.components.ArticleBox.articleInfo.category.maxCount - 1)?_c('nuxt-link',{key:category.entity._id,attrs:{"to":_vm.localePath({
              ...(category.entity.master_category && category.entity.master_category.length ? {
                name: 'articleList-slug-child',
                params: {
                  slug: category.entity.master_category[0].entity.page_meta.slug,
                  child: category.entity.page_meta.slug }
              }: {
                name: 'articleList-slug',
                params: { slug: category.entity.page_meta.slug }
              })
            })}},[_vm._v(_vm._s(_vm._f("prepositions")(category.entity.title)))]):_vm._e()})],2):_vm._e(),(
            _vm.$themeSettings.components.ArticleBox.articleInfo.author.enable
              && _vm.article.article_author_reference && _vm.article.article_author_reference[0]
          )?_c('div',{staticClass:"ArticleBox__author",attrs:{"itemprop":"author","itemscope":"itemscope","itemtype":"https://schema.org/Person"}},[(_vm.$themeSettings.components.ArticleBox.articleInfo.author.image.enable
              && _vm.article.article_author_reference.length
            && _vm.article.article_author_reference[0].entity.image.length)?_c('figure',{staticClass:"ArticleBox__infoAuthorImageWrapper",attrs:{"itemprop":"image","itemscope":"itemscope","itemtype":"https://schema.org/ImageObject"}},[_c('AppImage',{attrs:{"src":_vm.article.article_author_reference[0].entity.image[0].path,"width":_vm.$themeSettings.components.ArticleBox.articleInfo.author.image.width,"height":_vm.$themeSettings.components.ArticleBox.articleInfo.author.image.height,"alt":_vm.article.article_author_reference[0].entity.image[0].alt
                ? _vm.article.article_author_reference[0].entity.image[0].alt
              : _vm.article.article_author_reference[0].entity.title,"lazy":_vm.lazyImage,"itemprop":"contentUrl","image-class":"ArticleBox__authorImage"}})],1):_c('span',{staticClass:"ArticleBox__infoIconWrapper"},[(_vm.$themeSettings.components.ArticleBox.showIcons && _vm.$themeSettings.components.ArticleBox.articleInfo.author.icon)?_c('svgicon',{attrs:{"icon":require('@icon/person.svg?raw')}}):_vm._e()],1),_vm._l((_vm.article.article_author_reference),function(author,index){return (index === 0)?_c('span',{key:author.entity._id},[_c(_vm.$themeSettings.components.ArticleBox.articleInfo.author.showLink && author.entity.page_meta && author.entity.author_has_page ? 'nuxt-link' : 'span',{tag:"component",attrs:{"to":author.entity.page_meta && author.entity.author_has_page
                ? _vm.localePath({ name: 'author-slug', params: { slug: author.entity.page_meta.slug }})
              : false,"itemprop":"name"},domProps:{"textContent":_vm._s(author.entity.title)}}),(_vm.article.article_author_reference.length > 1)?_c('span',{staticClass:"ml-1"},[_vm._v(" (+"+_vm._s(_vm.article.article_author_reference.length - 1)+") ")]):_vm._e()],1):_vm._e()})],2):_vm._e()]):_vm._e()],1),(_vm.$themeSettings.components.ArticleBox.perex.enable && _vm.article.perex)?_c('div',{staticClass:"ArticleBox__perex",class:{'ArticleBox__perex--bottomOffset': _vm.$themeSettings.components.ArticleBox.linkReadMore.enable}},[_vm._v(" "+_vm._s(_vm._f("prepositions")(_vm._f("truncate")(_vm.article.perex,200)))+" ")]):_vm._e(),(_vm.$themeSettings.components.ArticleBox.linkReadMore.enable || _vm.forceLinkReadMore)?_c('div',{staticClass:"ArticleBox__linkReadMore",class:{'ArticleBox__linkReadMore--link': _vm.$themeSettings.components.ArticleBox.linkReadMore.button === 'link'}},[_c('nuxt-link',{class:_vm.$themeSettings.components.ArticleBox.linkReadMore.button !== 'link' ? `btn btn-${_vm.$themeSettings.components.ArticleBox.linkReadMore.button}` : '',attrs:{"to":_vm.article.page_meta && _vm.article.page_meta.slug
            ? _vm.localePath({ name: 'article-slug', params: { slug: _vm.article.page_meta.slug }})
            : '#'}},[_vm._v(" "+_vm._s(_vm.$t('ArticleBox.linkReadMore'))+" ")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }