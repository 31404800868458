<template>
  <PageContentPage
    v-if="contentPage"
    :page="contentPage"
    :menu-item="menuItem"/>
  <PageCatalog
    v-else
    ref="catalog"
    :bestsellers="bestsellers"
    :catalog-settings="catalogSettings"
    :category-cms="categoryCms"
    :category-store="categoryStore"
    :init-catalog-scope="catalogScope"
    :init-catalog-state="catalogState"
    :init-canonical-categories="canonicalCategories"
    :init-page="page"
    :init-per-page="perPage"
    :init-products="products"
    :menu-item="menuItem"
    :virtual-category-cms="virtualCategoryCms"/>
</template>

<script>
import { chunk, get } from 'lodash'
import AppRouteMixin from '~/mixins/AppRouteMixin'
import PageCatalog from '@theme/components/product/PageCatalog'
import PageContentPage from '@theme/components/content/PageContentPage'
import _ from 'lodash'
import parseParams from '~/app/parseParams'

export default {
  meta: {
    scrollPos: {
      x: 0,
      y: 0,
    },
  },
  name: 'RouteCatalog',
  middleware({ store }) {
    store.commit('locale/SET_ALTERNATIVE_PATHS', {
      cs: '/:slug',
      de: '/:slug',
      en: '/:slug',
      sk: '/:slug',
    })
  },
  components: {
    PageCatalog,
    PageContentPage,
  },
  mixins: [AppRouteMixin],
  async asyncData({ store, params, query, redirect, req, res, app, route, error }) {
    if (process.server) {
      res.set('X-Inspishop-Cache-Control', `public, max-age=${app.$env.STATELESS_TTL}`)
    }

    const slugLocaleReqex = new RegExp(`^/${app.i18n.locale}/`)
    const slug = route.path
      .replace(slugLocaleReqex, '/')
      .replace('/', '')
      .replace(/\/$/, '')
    const slugWithTrailingSlash = slug[slug.length - 1] !== '/' ? `${slug}/` : slug
    const slugSlashParts = slug.split('/')

    // Handle duplicates
    if (query.p === '0') {
      delete query.p
      redirect(301, req.path, query)
      return
    }

    const perPage = app.$themeSettings.components.PageCatalog.perPage
    const page = Number(query.p) > 0 ? Number(query.p) : 0

    const slugs = [slug, slugSlashParts[0], slugWithTrailingSlash, slugSlashParts[0] + '/']

    // Determine if page with this slug exists
    const categoryStoreResponse = await store.dispatch('category/LOAD_CATEGORIES', {
      slugs,
      checkPermissions: true,
    })
    if (categoryStoreResponse && categoryStoreResponse.error && categoryStoreResponse.error.statusCode) {
      return error({ statusCode: categoryStoreResponse.error.statusCode })
    }

    let categoryStore
    let virtualCategoryCms
    let categoryCms
    let seoParamsParsed
    let contentPage

    if (categoryStoreResponse.length === 0) {
      virtualCategoryCms = await store.dispatch('category/LOAD_VIRTUAL_CATEGORY', { slugs: [slug, slugSlashParts[0]] })
      if (!virtualCategoryCms) {
        contentPage = await store.dispatch('page/LOAD_CONTENT_PAGE', {
          slug: [slug, slugSlashParts[0]],
        })
        if (contentPage) {
          const menuItem = await store
            .dispatch('menu/FIND_MENU_ITEMS', {
              ids: [contentPage._id],
              menu: 'mainMenu',
            })
            .then(menuItems => menuItems[0])
          return { contentPage, menuItem }
        } else {
          // Return 404
          return app.$pageNotFound({ slug, type: 'product-categories' })
        }
      }
    } else {
      categoryStore = categoryStoreResponse[0]
      categoryCms = await store.dispatch('category/LOAD_CATEGORY_CMS', { storeId: categoryStore.id })
    }

    // Exclude cases for exact match of two segment store category paths
    if (!(categoryStore && [slug, slugWithTrailingSlash].includes(categoryStore.slug))) {
      if (slugSlashParts.length === 2) {
        seoParamsParsed = parseParams(slugSlashParts[1])
      } else if (slugSlashParts.length > 2) {
        return app.$pageNotFound({ slug, type: 'product-categories' })
      }
    }

    const params_slice = virtualCategoryCms
      ? virtualCategoryCms.parameters.map(p => {
          return {
            id: p.entityId,
            v: p.values,
          }
        })
      : []

    const tags_slice = virtualCategoryCms
      ? virtualCategoryCms.sliced_tags
        ? virtualCategoryCms.sliced_tags.split(',')
        : []
      : []

    const catalogSettings = virtualCategoryCms
      ? virtualCategoryCms.catalog_settings
      : categoryCms
        ? categoryCms.catalog_settings
        : null

    const selected = [
      ...(query.nf ? parseParams(query.nf) : []),
      ...(seoParamsParsed ? seoParamsParsed : []),
      ...params_slice,
    ]

    const selectedTags = query.tags ? query.tags.split('.') : null

    const virtualCategoryPriceRange =
      virtualCategoryCms &&
      virtualCategoryCms.price_range &&
      virtualCategoryCms.price_range.find(price => price.currency_code === store.state.currency.activeCurrency.code)

    // Prepare scope for catalog
    const catalogScope = {
      c: [
        ...(virtualCategoryCms
          ? virtualCategoryCms.sliced_categories
            ? virtualCategoryCms.sliced_categories.map(p => p.entityId)
            : []
          : [categoryStore.id]),
      ],
      ce: [
        ...(virtualCategoryCms
          ? virtualCategoryCms.sliced_categories_excluded
            ? virtualCategoryCms.sliced_categories_excluded.map(p => p.entityId)
            : []
          : []),
      ],
      params: [
        ...(catalogSettings && catalogSettings.parameters ? catalogSettings.parameters.map(p => p.entityId) : []),
      ],
      params_slice,
      tags_slice,
      selected,
      selectedTags,
      price: query.price ? query.price.split('-').map(p => (p ? Number(p) : null)) : null,
      price_slice: virtualCategoryPriceRange
        ? [virtualCategoryPriceRange.price_from, virtualCategoryPriceRange.price_to]
        : null,
      sort: query.sort ? query.sort : app.$themeSettings.catalog.defaultSortType,
      stock: query.stock ? query.stock === '1' : false,
    }

    // Get catalog state given scope, active params from URL, etc.
    let catalogState = await store.dispatch('catalog/LOAD_CATALOG', { catalogScope, catalogSettings }) // TODO: Add active params from URL

    // Check if selected seoParams params are allowed on this catalog page
    if (seoParamsParsed) {
      const marksSlug = 'vyrobce'

      for (let seoParam of seoParamsParsed) {
        let isValid = false
        for (let parameter of catalogState.parameters) {
          const seoParamsValue = parameter.values.find(value => seoParam.valueSlug === value.slug)
          if (seoParamsValue && (parameter.importantFilter || parameter.slug === marksSlug)) {
            isValid = true
          }
        }

        if (!isValid) {
          // Load parent category based on slugs if parameter slug is not valid anymore as filter
          virtualCategoryCms = await store.dispatch('category/LOAD_VIRTUAL_CATEGORY', {
            slugs: [seoParam.valueSlug, slug, slugSlashParts[0]],
          })

          if (!virtualCategoryCms) {
            return app.$pageNotFound({ slug, type: 'product-categories' })
          }
        }
      }
    }

    if (catalogState.filteredIds.length === 0 && !seoParamsParsed) {
      catalogState.parameters.forEach(p => (p.values = []))
    }

    // Load products for current page
    let products = []

    if (query && query.op && query.p && Number(query.op) < Number(query.p)) {
      for (let i = Number(query.op); i < page + 1; i++) {
        products.push(
          ...(await store.dispatch('product/LOAD_PRODUCTS', {
            ids: _.chunk(catalogState.filteredIds, perPage)[i],
          })),
        )
      }
    } else {
      products = store.dispatch('product/LOAD_PRODUCTS', {
        ids: _.chunk(catalogState.filteredIds, perPage)[page],
      })
    }

    store.commit('catalog/SET_SLUG', (categoryStore && categoryStore.slug) || slugSlashParts[0])

    const menuItem = store
      .dispatch('menu/FIND_MENU_ITEMS', {
        ids: [virtualCategoryCms ? virtualCategoryCms._id : categoryStore.id],
        menu: 'mainMenu',
      })
      .then(menuItems => menuItems[0])

    // Load canonical categories
    const canonicalCategories = store.dispatch('category/LOAD_CANONICAL_CATEGORIES', {
      catalogState,
      categoryStore,
    })

    // Wait for all unfinished calls
    const asyncData = {
      bestsellers: [], // Todo add while sorting category
      canonicalCategories,
      catalogSettings,
      products,
      page,
      perPage,
      catalogState,
      categoryStore,
      categoryCms,
      catalogScope,
      contentPage,
      virtualCategoryCms,
      menuItem,
    }
    let data = await Promise.allValues(asyncData)
    if (app.$contentSettings.functions.ssrPropertyPicker && process.server) {
      const mixin = (await import('~/mixins/SSRPropertyPickMixin.vue')).default

      data = mixin.methods.pickKeysFromPayload({
        payload: data,
        rules: mixin.computed.ssrPayloadRules(),
        pickers: mixin.computed.ssrPayloadPickers(),
      })
    }

    await app.apolloProvider.defaultClient.cache.reset()
    store.commit('locale/SET_CANONICALS', {
      canonicalSlugs:
        get(data, 'canonicalCategories.filteredCategory.page_meta.canonical_slugs') ||
        get(data, 'virtualCategoryCms.page_meta.canonical_slugs') ||
        get(data, 'categoryStore.canonicalSlugs', []),
    })

    return data
  },
}
</script>
