import AppTitle from '@theme/components/atom/AppTitle'
import ProductsRecommended from '@theme/components/product/ProductsRecommended'

export default {
  props: {
    categoryCms: Object,
    categoryStore: Object,
    virtualCategory: Object,
  },
  components: {
    AppTitle,
    ProductsRecommended,
  },
  data() {
    return {
      hasProducts: false,
    }
  },
  mounted() {
    this.$refs.recommendation.$on('componentLoaded', () => {
      this.hasProducts = this.$refs.recommendation.products.length > 0
    })
  },
}
